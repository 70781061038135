let awsRegion = 'us-west-2';

export default {
 api_urls: {
   reporting: 'https://2d5q1a89mg.execute-api.us-west-2.amazonaws.com/api',
   geojson: 'https://mtup6l9v8a.execute-api.us-west-2.amazonaws.com/api',
   overlays: 'https://f8j5c6j0ya.execute-api.us-west-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.testing.newmont.forwoodsafety.com',
 forwood_id_url: 'https://id.testing.newmont.forwoodsafety.com?redirect_uri=https://geoeditor.testing.newmont.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.testing.newmont.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-west-2_4KPzdpyw8',
   userPoolWebClientId: '3407g7mndv078g0ek52daljf0s',
   cookieStorage: {
     domain: '.testing.newmont.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
